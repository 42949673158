$secondary: #414141;
$success: #9ef029;
$dark: rgb(8, 8, 8);
$info: #005250;
$body-bg: #f5f5f500;
// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;1,800&family=Roboto:wght@500&display=swap');

@import "bootstrap";


/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #5a5a5a;
  font-family: 'Montserrat', sans-serif;
}

html {
  background: black;
  overflow-x: hidden;
}

/*
 * Header
 */

.nav-masthead .nav-link {
  color: rgba(255, 255, 255, .5);
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link+.nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
  background-color: #777;
}

.carousel-item>img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.marketing h2 {
  font-weight: 400;
}

.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0;
  /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.05rem;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {

  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}


/* SPECIFIC ELEMENTS CSS
---------------------------------------------------*/

.products-img {
  width: 100%;
  height: 350px;
}

.carousel-labels {
  text-align: left !important;
}

.carousel-height {
  height: 25rem !important;
}

.principal-text {
  font-size: 55px;
  font-weight: bold;
  font-family: Montserrat;
}

/*
#shield {
  background-image: url("./assets/textura1.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
}*/

#root {
  background-image: url("./assets/fondo-2.jpg") !important;
  background-size: auto;
  background-repeat-y: repeat;
}