.section {
    padding: 80px 0;
    position: relative;
    display: block;
}


@media (min-width: 768px) {
    .docs-sidebar {
        padding-top: 20px;
        padding-left: 20px
    }
}


@media (min-width: 992px) {
    .docs-sidebar .nav ul {
        display: none;
        padding-bottom: 10px;
    }
    .docs-sidebar .nav>.active>ul {
        display: block
    }
}

.docs-sidebar .nav>li>a {
    color: #ffffff;
    border-left: 2px solid transparent;
    padding: 0 20px;
    font-size: 15px;
    font-weight: 400;
}

.docs-sidebar .nav .nav>li>a {
    padding-left: 40px;
    font-size: 14px;
}

.docs-sidebar .nav>li:not(.active)>a:hover {
    color: #9ef029;
    text-decoration: none;
    background-color: transparent;
    border-left-width: 1px;
    border-left-color: #9ef029;
}

.docs-sidebar .nav>li>a:focus {
    text-decoration: none;
    background-color: transparent;
}


.docs-sidebar .nav>.active>a {
    color: #0E97EE;
    text-decoration: none;
    background-color: transparent;
    border-left-color: #0E97EE;
}


.docs-sidebar .nav>.active>a,
.docs-sidebar .nav>.active:hover>a,
.docs-sidebar .nav>.active:focus>a {
    font-weight: 700;
}


.docs-sidebar .nav .nav>.active>a,
.docs-sidebar .nav .nav>.active:hover>a,
.docs-sidebar .nav .nav>.active:focus>a {
    font-weight: 500;
}

.docs-sidebar .nav a {
    text-decoration: none;
}


ol,
h1,
h2,
h3,
h4 {
    color: #ffffff;
}





